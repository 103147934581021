<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 9.00001C13.9667 9.00001 13.7294 8.90401 13.538 8.71201C13.346 8.52068 13.25 8.28334 13.25 8.00001C13.25 7.71668 13.346 7.47901 13.538 7.28701C13.7294 7.09568 13.9667 7.00001 14.25 7.00001H21.25C21.5334 7.00001 21.7707 7.09568 21.962 7.28701C22.154 7.47901 22.25 7.71668 22.25 8.00001C22.25 8.28334 22.154 8.52068 21.962 8.71201C21.7707 8.90401 21.5334 9.00001 21.25 9.00001H14.25ZM14.25 17C13.9667 17 13.7294 16.904 13.538 16.712C13.346 16.5207 13.25 16.2833 13.25 16C13.25 15.7167 13.346 15.479 13.538 15.287C13.7294 15.0957 13.9667 15 14.25 15H21.25C21.5334 15 21.7707 15.0957 21.962 15.287C22.154 15.479 22.25 15.7167 22.25 16C22.25 16.2833 22.154 16.5207 21.962 16.712C21.7707 16.904 21.5334 17 21.25 17H14.25ZM5.10005 10.3L2.95005 8.15001C2.76672 7.96668 2.67505 7.73334 2.67505 7.45001C2.67505 7.16668 2.76672 6.93334 2.95005 6.75001C3.13338 6.56668 3.36672 6.47501 3.65005 6.47501C3.93338 6.47501 4.16672 6.56668 4.35005 6.75001L5.77505 8.17501L9.32505 4.62501C9.52505 4.42501 9.75838 4.32901 10.025 4.33701C10.2917 4.34568 10.525 4.45001 10.725 4.65001C10.9084 4.85001 11 5.08334 11 5.35001C11 5.61668 10.9084 5.85001 10.725 6.05001L6.50005 10.3C6.30005 10.5 6.06672 10.6 5.80005 10.6C5.53338 10.6 5.30005 10.5 5.10005 10.3ZM5.10005 18.3L2.95005 16.15C2.76672 15.9667 2.67505 15.7333 2.67505 15.45C2.67505 15.1667 2.76672 14.9333 2.95005 14.75C3.13338 14.5667 3.36672 14.475 3.65005 14.475C3.93338 14.475 4.16672 14.5667 4.35005 14.75L5.77505 16.175L9.32505 12.625C9.52505 12.425 9.75838 12.329 10.025 12.337C10.2917 12.3457 10.525 12.45 10.725 12.65C10.9084 12.85 11 13.0833 11 13.35C11 13.6167 10.9084 13.85 10.725 14.05L6.50005 18.3C6.30005 18.5 6.06672 18.6 5.80005 18.6C5.53338 18.6 5.30005 18.5 5.10005 18.3Z"
      fill="#365FA3"
    />
  </svg>
</template>
